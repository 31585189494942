const initialState = {
  open: true,
}

const reducer = (state, action) => {
  switch (action.type) {
    case "close":
      return { open: false }
    default:
      throw new Error()
  }
}

export { reducer, initialState }
